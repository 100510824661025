@import url('https://fonts.googleapis.com/css?family=Raleway|Viga');

// $primary: #a51616;
$primary: #d51f30; /* MAIN COLOR */
$secondary: #950f1b; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		align-items: center;
		font-family: 'Viga', sans-serif;
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

.bg {
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	background-position: 100% 50%;
	@media (max-width: 767px) {
		background-position: 60% 50%;
	}
}

.padTopBtm {
	padding: 100px 0;
}
.padTopBtm2 {
	padding: 50px 0;
}
.padTopBtm3 {
	padding: 150px 0;
	@media (max-width: 767px) {
		padding: 50px 0;
	}
}
.nav.navbar-nav {
	margin-top: 20px;
}

.cta {
	@include btn1;
	margin: 20px 0;
	padding: 30px 80px;
	font-size: 20px;
	border-radius: 10px;
	@media (max-width: 350px) {
		width: 100%;
		padding: 20px 40px;
	}
}
.bg2 {
	background: url(../img/banner2.jpg) no-repeat;
	padding: 250px 0;
	background-size: cover;
	position: relative;
	background-attachment: fixed;
	@media (min-width: 1200px) and (orientation: landscape) {
		background-attachment: scroll;
	}
	@media (max-width: 1024px) {
		background-attachment: scroll;
	}
	@media (max-width: 991px) {
		padding: 150px 0;
	}
	@media (max-width: 767px) {
		padding: 75px 0;
		background-position: 45% 50%;
	}
} 

.bg2::before {
	content: "";
	background: rgba(100,15,27,0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.bg2, .bg3 {
	h1,p {
		color: white;
	}
	p {
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
}

.bg3 {
	background: url(../img/banner3.jpg) no-repeat;
	padding: 250px 0;
	background-size: cover;
	position: relative;
	background-attachment: fixed;
	@media (min-width: 1200px) and (orientation: landscape) {
		background-attachment: scroll;
	}
	@media (max-width: 1024px) {
		background-attachment: scroll;
	}
	@media (max-width: 991px) {
		padding: 150px 0;
	}
	@media (max-width: 767px) {
		padding: 75px 0;
		background-position: 20% 50%;
	}
} 

.bg3::before {
	content: "";
	background: rgba(100,15,27,0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

p {
	font-size: 22px;
	font-family: 'Raleway', sans-serif;
	@media (max-width: 767px) {
		font-size: 18px;
	}
}

h1 {
	font-size: 40px;
	font-family: 'Viga', sans-serif;
}
h2 {
	font-family: 'Viga', sans-serif;
}

.bg4 {
	background: $secondary;
	h1,h2,p {
		color: white;
	}
}

.icon {
	padding: 15px;
	max-width: 200px;
	width: 100%;
	display: block;
	margin: 0 auto;
}
h1.title {
	padding-bottom: 35px;
	@media (max-width: 767px) {
		padding: 0px 15px 35px 15px;
	}
}

.logo {
	max-width: 200px;
	width: 100%;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}
.top-pad li {
	font-family: 'Raleway', sans-serif;
}
.pad {
	padding: 50px 0;
}

.couple {
	@media (max-width: 991px) {
		max-width: 450px;
		width: 100%;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.form {
	background: rgba(149,15,27,0.8);
	h1 {
		color: white;
		padding-top: 10px;
	}
}

@media (max-width: 767px) {
	.form {
		max-width: 400px;
		width: 100%;
		display: block;
		margin: 0 auto;
	}
	.padTopBtm {
		padding: 20px 0;
	}
}

input#username, input#password {
 @media (max-width: 1024px) {
  font-size: 16px;
 }
}
